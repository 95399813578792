var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"pc-main"},[_c('div',{staticClass:"main-table"},[_c('el-form',{attrs:{"inline":true,"size":_vm.theme.size}},[_c('el-form-item',[_c('el-date-picker',{attrs:{"picker-options":_vm.$config.DATE_PICKER,"type":"datetimerange","value-format":"yyyy-MM-dd HH:mm:ss","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期"},on:{"change":function($event){return _vm.getClients(_vm.params)}},model:{value:(_vm.params.date),callback:function ($$v) {_vm.$set(_vm.params, "date", $$v)},expression:"params.date"}})],1)],1),_c('div',[_c('el-table',{staticClass:"scroll-wrapper ssss absolute",attrs:{"max-height":(_vm.theme.height - 160)+'px',"header-cell-style":{backgroundColor: '#e1d6ba', color: '#000'},"cell-style":_vm.cellStyle,"data":_vm.clients,"size":_vm.theme.size,"summary-method":_vm.getSummaries,"show-summary":""},on:{"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"label":"渠道","prop":"channel","min-width":"87"}}),_c('el-table-column',{attrs:{"label":"平台","prop":"source","min-width":"87","formatter":function (ref) {
var source = ref.source;
 return _vm.sources[source]; }}}),_c('el-table-column',{attrs:{"label":"客户量","prop":"stock","min-width":"81","sortable":"custom"}}),_c('el-table-column',{attrs:{"label":"添加量","prop":"added","min-width":"81","sortable":"custom"}}),_c('el-table-column',{attrs:{"label":"成交量","prop":"dealed","min-width":"81","sortable":"custom"}}),_c('el-table-column',{attrs:{"label":"添加率","min-width":"81","formatter":function (ref) {
var stock = ref.stock;
var added = ref.added;
 return ((added/stock)*100).toFixed(2)-0; }}}),_c('el-table-column',{attrs:{"label":"成交率","min-width":"81","formatter":function (ref) {
var stock = ref.stock;
var dealed = ref.dealed;
 return ((dealed/stock)*100).toFixed(2)-0; }}})],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }